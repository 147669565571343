<template>
  <div class="container-fluid">
    <div class="row flex-md-row flex-column">
      <div class="col-md-4 col-12 custom-col-md-4 d-flex align-items-center">
        <div id="myCarousel" class="carousel slide" data-ride="carousel" v-if="images.length > 1">
          <ol class="carousel-indicators">
            <li
              v-for="(image, index) in images"
              :key="index"
              :data-target="'#myCarousel'"
              :data-slide-to="index"
              :class="{ active: index === 0 }"
            ></li>
          </ol>
          <div class="carousel-inner">
            <div
              v-for="(image, index) in images"
              :key="index"
              :class="['carousel-item', { active: index === 0 }]"
            >
              <img :src="image" alt="Image" class="d-block w-100" style="object-fit: cover;" />
            </div>
          </div>
          <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <div v-else>
          <img :src="images[0]" alt="Image" class="d-block w-100" style="object-fit: cover;" />
        </div>
      </div>
      <div class="col-md-6 col-12 custom-col-md-6">
        <h4>{{ title }}</h4><br />
        <p class="text-dark-gray" v-html="description"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require('@/assets/images/news/05/news_02.jpg'),
        //可輪播圖片
        //EX:require('@/assets/images/news/01/news_3.png'),
      ],
      //標題
      title: '【 TSK | ISDS 國際皮膚外科學會 】',
      //內文
      description:
        '<b>TSK即將參與“ISDS 國際皮膚外科學會”！ <br>我們的醫學美容尖針與鈍針，結合多項創新技術，為醫療專業人士提供安全、優質且令人安心的產品。期待在展會與您分享TSK的卓越技術與服務。<br><br> 展會時間：2024年9月13日(五) - 15日(日) <br> 展會地點：高雄展覽館<br> 攤位編號：F06',
    };
  },
};
</script>

<style scoped>
.custom-col-md-4 {
  flex: 0 0 auto;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-col-md-6 {
  flex: 0 0 auto;
  width: 60%;
}
@media (max-width: 767.98px) {
  .custom-col-md-4,
  .custom-col-md-6 {
    flex: 0 0 auto;
    width: 100%;
  }
}
</style>
